import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InquiryElement } from 'src/app/models/inquiry';
import { WasteStream } from 'src/app/models/waste-stream';
import { WasteTitle } from 'src/app/pipes/waste-title.pipe';
import { SkeletonComponent } from 'src/app/components/shared/skeleton/skeleton.component';

@Component({
  selector: 'app-waste-title',
  standalone: true,
  templateUrl: './waste-title.component.html',
  styleUrls: ['./waste-title.component.scss'],
  imports: [CommonModule, WasteTitle, SkeletonComponent],
})
export class WasteTitleComponent {
  @Input() element: InquiryElement | WasteStream;
}
